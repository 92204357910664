<template>
    <v-container>
        <tarjeta-datos titulo="Ventanas" icon="ev-station">
            <v-row>
                <!-- Cristal -->
                <aux-input title="Cristal *" class="col-sm-4 col-12">
                    <v-select filled placeholder="Cristal" dense hide-details="auto" v-model="form.cristal"
                        :items="cristalVentana" item-text="idCristal" />
                </aux-input>
                <aux-input>
                    <v-checkbox v-model="form.hasPersianas" label="Persianas"></v-checkbox>
                </aux-input>
            </v-row>

            <v-row>
                <!-- Material -->
                <aux-input title="Material *" class="col-sm-4 col-12">
                    <v-radio-group v-model="form.material" row>
                        <v-radio v-for="material in materialVentana" :key="material.id" :label="material.idMaterial"
                            :value="material.idMaterial" item-text="idMaterial"></v-radio>
                    </v-radio-group>
                </aux-input>

                <!-- Tipo -->
                <aux-input title="Tipo *" class="col-sm-4 col-12">
                    <v-select filled placeholder="Tipo" dense hide-details="auto" v-model="form.tipo"
                        :items="tipoVentana" item-text="idTipo" />
                </aux-input>
            </v-row>

            <!-- Medidas -->
            <v-row>
                <aux-input title="Altura *" class="col-sm-4 col-12">
                    <v-select filled dense hide-details="auto" v-model="form.alto" :items="form.opcionesAltura"
                        @change="medidas" />
                </aux-input>
                <aux-input title="Anchura *" class="col-sm-4 col-12">
                    <v-select filled dense hide-details="auto" v-model="form.ancho" :items="form.opcionesAnchura"
                        @change="medidas" />
                </aux-input>
            </v-row>
            <!-- Datos -->
            <v-row>
                <aux-input title="Cantidad *" class="col-sm-4 col-12">
                    <v-text-field filled dense v-model="form.cantidad" outlined required type="number"></v-text-field>
                </aux-input>
                <aux-input title="Codigo *" class="col-sm-4 col-12">
                    <v-text-field filled dense v-model="form.codigo" required outlined readonly></v-text-field>
                </aux-input>
            </v-row>
            <div class="d-flex justify-end">
                <v-btn color="error" class="ma-2" @click="clearForm">Limpiar</v-btn>
                <v-btn color="primary" class="ma-2" @click="addOrUpdateForm">{{ editIndex === -1 ? 'Añadir' :
                    'Actualizar' }}</v-btn>
            </div>

            <v-checkbox v-model="hideName" label="Ocultar nombre"></v-checkbox>
            <!-- Tabla-->
            <v-card class="mb-4">
                <v-divider></v-divider>
                <v-data-table :headers="computedHeaders" :items="allForms" class="elevation-1">
                    <template v-slot:item.nombre="{ item }">
                        <span v-if="!hideName">{{ item.nombre }} {{ item.ancho }}x{{ item.alto }}</span>
                    </template>
                    <template v-slot:item.acciones="{ item, index }">
                        <v-btn color="primary" class="mr-2" small @click="editForm(index)">
                            <v-icon>mdi-pen</v-icon>
                        </v-btn>
                        <v-btn color="error" small @click="deleteForm(index)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>
        </tarjeta-datos>
    </v-container>
</template>

<script>
import AuxInput from '../../../components/AuxInput.vue';
export default {
    components: { AuxInput },
    name: "WindowConfigForm",
    props: {
        value: { type: Array, default: () => [] },
    },
    computed: {
        allForms: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        computedHeaders() {
            let headers = [
                { text: 'Código', value: 'codigo' },
                { text: 'Cantidad', value: 'cantidad' },
                { text: 'Acciones', value: 'acciones', sortable: false },
            ];
            if (!this.hideName) {
                headers.unshift({ text: 'Nombre', value: 'nombre', align: 'start' });
            }
            return headers;
        }
    },
    data: () => ({
        form: {
            tipo: "Ventana",
            cristal: "1 hoja",
            material: "Blanco",
            hasPersianas: false,
            codigo_familia: "",
            cantidad: "",
            alto: null,
            ancho: null,
            fichero_medidas: "",
            codigo: "",
            opcionesAltura: [],
            opcionesAnchura: [],
            nombre: "",
        },
        editIndex: -1,
        hideName: false,
        conBalconera: ["1 hoja", "2 hojas"], //muestra opciones adiciones si se selecciona
        tipoVentana: [],
        cristalVentana: [],
        materialVentana: [],
    }),
    watch: {
        'form.tipo': 'buscarCodigoFamilia',
        'form.cristal': 'buscarCodigoFamilia',
        'form.material': 'buscarCodigoFamilia',
        'form.hasPersianas': 'buscarCodigoFamilia',
    },
    methods: {
        async getTipos() {
            const { data } = await axios({
                url: `${process.env.VUE_APP_API_URL}/ventanas-tipo`,
            });
            this.tipoVentana = data;
        },
        async getCristal() {
            const { data } = await axios({
                url: `${process.env.VUE_APP_API_URL}/ventanas-cristal`,
            });
            this.cristalVentana = data;
        },
        async getMaterial() {
            const { data } = await axios({
                url: `${process.env.VUE_APP_API_URL}/ventanas-material`,
            });
            this.materialVentana = data;
        },
        addOrUpdateForm() {
            if (this.form.cantidad && this.form.codigo) {
                const formCopy = {
                    ...this.form,
                    opcionesAltura: [...this.form.opcionesAltura],
                    opcionesAnchura: [...this.form.opcionesAnchura]
                };

                if (this.editIndex === -1) {
                    this.allForms.push(formCopy);
                } else {
                    this.$set(this.allForms, this.editIndex, formCopy);
                    this.editIndex = -1;
                }
                this.clearForm();
            } else {
                alert("Por favor, rellene todos los campos");
            }
        },
        clearForm() {
            this.form = {
                tipo: "Ventana",
                cristal: "1 hoja",
                material: "Blanco",
                hasPersianas: false,
                codigo_familia: "",
                cantidad: "",
                alto: null,
                ancho: null,
                fichero_medidas: "",
                codigo: "",
                opcionesAltura: [],
                opcionesAnchura: [],
                nombre: "",
            };
        },
        deleteForm(index) {
            this.allForms.splice(index, 1);
        },
        async editForm(index) {
            this.form = {
                ...this.allForms[index],
                opcionesAltura: [...this.form.opcionesAltura],
                opcionesAnchura: [...this.form.opcionesAnchura]
            };
            this.editIndex = index;
        },
        //cambiar nombre, genera codigo con medidas
        async medidas() {
            var alto = this.form.alto;
            var ancho = this.form.ancho;

            if (alto && ancho) {
                const paddedAlto = alto.toString().padStart(3, '0');
                const paddedAncho = ancho.toString().padStart(3, '0');

                this.form.codigo = `${this.form.codigo_familia}${paddedAncho}${paddedAlto}`;
            }
        },
        async buscarCodigoFamilia() {
            try {
                if (this.conBalconera && !this.conBalconera.includes(this.form.cristal)) {
                    this.form.tipo = "Ventana";
                }
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/ventanas/search`, {
                    params: {
                        idTipo: this.form.tipo,
                        idCristal: this.form.cristal,
                        idMaterial: this.form.material,
                        persiana: this.form.hasPersianas,
                    },
                });

                const data = response.data[0] || { codigo_familia: '', ancho: [], alto: [], nombre: '' };

                this.form.codigo_familia = data.codigo || '';
                this.form.opcionesAnchura = data.ancho || [];
                this.form.opcionesAltura = data.alto || [];
                this.form.nombre = data.nombre || '';
            } catch (error) {
                console.error("Error searching ventanas:", error);
                this.form.codigo_familia = '';
                this.form.opcionesAnchura = [];
                this.form.opcionesAltura = [];
            }
        },
        async buscarNombre() {
            for (const form of this.allForms) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_API_URL}/ventanas/search`, {
                        params: {
                            codigo: form.codigo_familia,
                        },
                    });

                    const data = response.data[0] || { nombre: '', idTipo: '', idCristal: '', idMaterial: '', persiana: '' };
                    this.$set(form, 'nombre', data.nombre || '');
                    this.$set(form, 'tipo', data.idTipo || '');
                    this.$set(form, 'cristal', data.idCristal || '');
                    this.$set(form, 'material', data.idMaterial || '');
                    this.$set(form, 'hasPersianas', data.persiana ?? false);
                } catch (error) {
                    console.error("Error searching ventanas:", error);
                    this.$set(form, 'nombre', '');
                    form.opcionesAnchura = [];
                    form.opcionesAltura = [];
                }
            }
        }
    },
    async created() {
        await this.getMaterial();
        await this.getTipos();
        await this.getCristal();
        await this.buscarNombre();
        await this.buscarCodigoFamilia(); //para tener medidas en la seleccion
    },
};
</script>
